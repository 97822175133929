<template>
  <div class="tixian">
    <!-- <title-top>我的订单</title-top> -->

    <div class="column txbg mb20">
      <div class="row flex-between mt50">
        <img class="imgsize44 ml25" src="~images/fanhuiw.png" alt @click="onBack" />
        <div class="row align-center" @click="onYeDetail">
          <img class="imgsize44" src="~images/txlog.png" alt />
          <span class="font32 f-white mr25">余额明细</span>
        </div>
      </div>
      <span class="row just-center font60 f-FFFFFF mt40">￥{{res.balance}}</span>
      <span class="row just-center font24 f-FFFFFF">可提现</span>

      <div class="row w100 flex-center mt50">
        <div class="column flex-center font24 f-FFFFFF" @click="onRecord">
          <span>￥{{res.withdrawn_amount}}</span>
          <div class="row align-center">
            <span>累计提现</span>
            <span class="row flex-center record br4 ml25">记录</span>
          </div>
        </div>
        <span class="gengexian ml150"></span>
        <div class="column font24 f-FFFFFF ml150">
          <span>￥{{res.uncollected_amount}}</span>
          <span>待结算</span>
        </div>
      </div>
    </div>
    <div class="row align-center bg-white h96 mb20">
      <span class="ml25 font28 f-1A1A1A fontwb mr40">提现金额(￥)</span>
      <input
        class="flex1 font28 f-999999 mr25"
        type="number"
        v-model="amount"
        placeholder="请输入提现金额"
      />
    </div>
    <div class="row font24 f-1A1A1A align-center bg-white h96">
      <span class="ml25 fontwb">选择到账日</span>
    </div>
    <div class="line"></div>
    <div class="row align-center between bg-white h146" @click="onTab(0)">
      <div class="row align-center ml25">
        <span class="font28 f-1A1A1A mr25">T+1</span>
        <span class="font24 f-999999">手续费15%</span>
      </div>
      <img class="imgsize32 mr25" src="~images/selecticon.png" alt v-if="currentIndex==0" />
      <img class="imgsize32 mr25" v-else src alt />
    </div>
    <div class="line"></div>
    <div class="row align-center between bg-white h146 mb20" @click="onTab(1)">
      <div class="row align-center ml25">
        <span class="font28 f-1A1A1A mr25">T+7</span>
        <span class="font24 f-999999">手续费10%</span>
      </div>
      <img class="imgsize32 mr25" src="~images/selecticon.png" alt v-if="currentIndex==1" />
      <img class="imgsize32 mr25" v-else src alt />
    </div>
    <div class="row font24 f-1A1A1A align-center bg-white h96">
      <span class="ml25 fontwb">选择到账平台</span>
    </div>
    <div class="line"></div>
    <div class="row align-center between bg-white h146">
      <div class="row align-center ml25">
        <img class="imgsize48 mr25" src="~images/zfbicon.png" alt />
        <span class="font28 f-1A1A1A">支付宝</span>
        <div
          v-if="isBind"
          @click="onUnBind"
          class="ml25 f-F62341 font28"
          style="border:1px solid #F62341;padding:4px; "
        >解绑</div>
      </div>
      <img class="imgsize32 br50 mr25 bj" src="~images/selecticon.png" alt />
    </div>
    <div class="row flex-center" @click="onSure">
      <div class="btn-red mt170">提现</div>
    </div>

    <div class="column flex-center model-mask" v-if="isShow">
      <div class="column align-center whitescreen font32 f-1A1A1A" style="width: 7.893333rem;">
        <span class="row just-center h98 fontwb" style="align-items: flex-end;">支付密码</span>
        <input class="area font28 mt40 mb40" style="padding-left:10px;" v-model="paypsw" />
        <div class="line"></div>
        <div class="row flex-center h96">
          <span
            class="row flex-center f-999999 h96"
            style="width: 3.946667rem;border-right: .013333rem solid #E6E6E6;"
            @click="onCancle"
          >取消</span>
          <span class="flex1 row flex-center" style="width: 3.946667rem;" @click="onPay">确定</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import {
  overview,
  withdrawals,
  get_withdrawals,
  alipay_user_info,
  certification_state,
  member,
  delete_alipay_user_info,
  alipay_user_info_edit
} from "network/igou";
export default {
  name: "TiXian",
  data() {
    return {
      res: {},
      currentIndex: 0,
      arrival_periods: 1,
      amount: 0,
      isBind: false,
      isAuthCard: false,
      isPayPsw: false,
      isShow: false,
      paypsw: ""
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    onCancle() {
      this.isShow = false;
    },
    initData() {
      this.get_overview();
      this.check_alipay_user_info();
      this.check_certification_state();
      this.check_member();
    },
    fn_alipay_user_info_edit() {
      alipay_user_info_edit().then(res => {
        console.log(res.data.auth_request_parameters);
        let params = res.data.auth_request_parameters;
        let app_id = this.getKey(params, "app_id");
        let scope = this.getKey(params, "scope");
        console.log(app_id, scope);
        // 支付宝获取Auth_code地址
        let getAuthCodeUrl =
          "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=" +
          app_id +
          "&scope=" +
          scope +
          "&redirect_uri=www.baidu.com";
          console.log(getAuthCodeUrl)
          return;
        window.location.href = getAuthCodeUrl;
      });
    },
    getKey(res, key) {
      let arr = res.split("&");
      for (let i = 0; i < arr.length; i++) {
        let tmp = arr[i].split("=");
        if (tmp[0] == key) {
          return tmp[1];
        }
      }
      return "";
    },
    onPay() {
      if (!this.paypsw) {
        this.$toast.show("支付密码不能为空");
        return;
      }
      this.isShow = false;
      this.post_withdrawals();
    },
    check_member() {
      member().then(res => {
        console.log(res);
        this.isPayPsw = res.data.has_payment_password;
      });
    },
    check_certification_state() {
      certification_state().then(res => {
        console.log(res);
        if (res.data.state == "certified") {
          this.isAuthCard = true;
        } else {
          this.isAuthCard = false;
        }
      });
    },
    check_alipay_user_info() {
      alipay_user_info().then(
        res => {
          //console.log(res);
          this.isBind = true;
        },
        error => {
          if (error.response && error.response.status == 404) {
            this.isBind = false;
          }
        }
      );
    },
    onUnBind() {
      delete_alipay_user_info().then(res => {
        this.initData();
      });
    },
    onYeDetail() {
      this.$router.push("/yedetail");
    },
    onRecord() {
      this.$router.push("/txrecord");
    },
    onSure() {
      this.$toast.show("提现请前往app");
      return;
      if (!this.amount) {
        this.$toast.show("请填写提现金额");
        return;
      }
      //1判断是否绑定授权
      //1.1如果没授权，发起授权
      if (!this.isBind) {
        this.fn_alipay_user_info_edit();
        return;
      }
      //2判断是否已个人认证
      if (!this.isAuthCard) {
        this.$router.push("/comfirm");
        return;
      }
      //3是否设置过支付密码
      //3.1如果没有，去设置支付密码页面
      if (!this.isPayPsw) {
        this.$router.push("/xgpaypsw");
        return;
      }
      //2.2如果认证，弹出输入支付密码的框
      this.isShow = true;
    },
    post_withdrawals() {
      withdrawals(this.amount).then(res => {
        this.$toast.show("提现成功");
      });
    },
    onTab(index) {
      this.currentIndex = index;
      if (index == 0) {
        this.arrival_periods = 1;
      } else {
        this.arrival_periods = 7;
      }
    },
    get_overview() {
      overview().then(res => {
        console.log(res);
        this.res = res.data;
      });
    },
    onBack() {
      this.$router.back();
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.area {
  border: none;
  outline: none;
  width: 6.586667rem;
  height: 1.28rem;
  background: rgba(230, 230, 230, 1);
  border-radius: 0.266667rem;
}
.txbg {
  width: 100%;
  height: 5.6rem;
  background: url(~images/tixianbg.png) no-repeat;
  background-size: 100% 5.6rem;
}
.gengexian {
  width: 0.026667rem;
  height: 0.8rem;
  background-color: #ffffff;
}
.record {
  border: 1px solid white;
  padding: 0.066667rem;
}
.bj {
  border: 1px solid rgba(224, 224, 224, 1);
}
</style>
